/**
 * Device 设备列表
 * @type {{}}
 */
import Vue from "vue";
import { Input, Icon, Cascader, Select, Option, Message, Table, Page, Modal } from "view-design";
import { mapMutations, mapState } from "vuex";
import { getManufacturerService,getDeviceListService,delDeviceInfoService } from "@/service/device-info-service";
import { deviceListColumns } from '@/service/device-info-service'
import html2canvas from 'html2canvas'
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Cascader', Cascader);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Table', Table);
Vue.component('Page', Page);
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import vueQr from 'vue-qr'
import { saveCode } from "@/utils/tools";
const DeviceList = {
    name:'DeviceList',
    components:{ vueQr },
    computed: {
        ...mapState({
            classifyMenuArray: state => state.system.classifyMenuArray, //分类菜单数据
            selectedMenuId: state => state.system.selectedMenuId, //选中的菜单Id
        }),
    },
    data() {
        return {
            deviceListColumns:deviceListColumns, //设备列表显示字段
            manufacturerArray:[], //生产厂家数据
            selectAllCancel:[], //全选时的临时存储
            deviceListArray:{}, //设备列表数据
            qrCodeVisible:false, //code modal 可显示
            deviceLoading:false,
            deviceListHeight:0, //表格高度
            windowWidth:0, //窗口宽度
            QRCode:'',
            QRObj:{},
            logoSrc:require("@/assets/Icon/logo-img.png"),
            deviceListParams:{ //提交的数据集
                deviceType:[], //设备分类
                manufacturer:'',// 生产厂家
                search:'', //搜索条件
                checkInput:[],//选择的数据列表项
                currentPage: 1,  //当前页
                displayNumber:10 // 显示条数
            }
        }
    },
    created() {
        /**
         * 订阅分类菜单列表事件
         */
        $event.$on(EVENT_NAME.DEVICE_MENU_LIST, () => {
            this.deviceListParams.deviceType = this.selectedMenuId
            this.deviceListParams.currentPage = 1
            this.onDeviceListAll().then()
        });
        this.deviceListParams.deviceType = this.selectedMenuId
        this.onManufacturerAll().then()
        this.onDeviceListAll().then()
        this.onTableHeight()
    },
    mounted() {
        window.addEventListener('resize', this.onTableHeight)
    },
    methods: {
        ...mapMutations('system',['SET_DEVICE_INFO','SET_TAB_ID']),
        /**
         * 计算table高度
         */
        onTableHeight(){
            this.deviceListHeight = window.innerHeight - 230
            this.windowWidth = window.innerWidth
        },
        /**
         * 重置
         */
        onReset() {
            this.deviceListParams.deviceType = []
            this.deviceListParams.manufacturer = ''
            this.deviceListParams.search = ''
        },
        /**
         * 搜索
         */
        onSearch(){
            this.onDeviceListAll().then()
        },
        /**
         * 下载图片
         */
        onDowns(){
            html2canvas(this.$refs.deviceRef,{
                backgroundColor: null
            }).then((canvas) => {
                let dataURL = canvas.toDataURL("image/png");
                saveCode(this.QRObj.equipmentName,dataURL)
            });
        },
        /**
         * 新增
         */
        onAddList() {
            if(this.classifyMenuArray[0].children.length === 0){
                Message.warning('请先创建设备分类！')
                return
            }
            this.$router.push('/device/add')
        },
        /**
         * 删除
         * @param id
         */
        onDelete(id) {
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要删除这条信息吗？</p>',
                onOk: () => {
                     this.onDeviceList(id).then()
                }
            });
        },
        /**
         * 查看二维码
         * @param row
         */
        onCode(row){
            //'https://expert.ustep.cn/device/info?id='+row.id
            // this.QRCode = row.id
            const env = process.env.NODE_ENV;
            this.QRCode = env === 'production'?'https://lingyun.ustep.cn/device/info?id='+row.id : 'https://expert.ustep.cn/device/info?id='+row.id
            this.QRObj = row
            this.qrCodeVisible = true
        },
        /**
         * 跳转详情
         * @param row
         */
        onLinkInfo(row,type) {
            this.SET_DEVICE_INFO(row)
            this.SET_TAB_ID(type)
            this.$router.push('/device/info')
        },
        /**
         * 发送当前页
         * @param number
         */
        onSearchPage(number){
            this.deviceListParams.currentPage = number
            this.onDeviceListAll().then()
        },
        /**
         * 发送每页显示的条数
         * @param number
         */
        onSearchPageSize(number){
            this.deviceListParams.displayNumber = number
            this.onDeviceListAll().then()
        },
        /**
         * 删除数据
         */
        onRemoveList() {
            if(this.deviceListParams.checkInput.length === 0){
                Message.error('请选择您要删除的数据！')
                return;
            }
            if(this.deviceListParams.checkInput.length>1){
                Message.error('抱歉，暂时只支持单条删除！')
                return
            }
            this.onDelete(this.deviceListParams.checkInput[0])
        },
        /**
         * 点击全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectAll(selection) {
            this.selectAllCancel = selection
            for(let key in selection){
                let flag = true
                for(let index in this.deviceListParams.checkInput){
                    if(selection[key].id === this.deviceListParams.checkInput[index]){
                        flag = false
                    }
                }
                if(flag){
                    this.deviceListParams.checkInput.push(selection[key].id)
                }
            }
        },
        /**
         * 点击取消全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectAllCancel() {
            let selection = this.selectAllCancel
            for(let key in selection){
                for(let index in this.deviceListParams.checkInput){
                    if(selection[key].id === this.deviceListParams.checkInput[index]){
                        this.deviceListParams.checkInput.splice(index,1);
                    }
                }
            }
        },
        /**
         * 选中某以一项时触发
         * selection: 以选项数据
         * row: 刚选择的数据
         * some() 方法用于检测数组中的元素是否满足指定条件
         * 注意： some() 不会对空数组进行检测。
         * 注意： some() 不会改变原始数组。
         * @param selection，row
         */
        onSelect(selection,row) {
            let mediaList = this.deviceListParams.checkInput.some((e)=>{
                return e.id === row.id
            })
            if(!mediaList){
                this.deviceListParams.checkInput.push(row.id)
            }
        },
        /**
         * 取消选中某一项时触发
         * selection: 以选项数据
         * row: 取消选择的项数据
         * 遍历当前选中数组 如有相同则在当前数组的指定位置进行删除
         * @param selection，row
         */
        onSelectCancel(selection,row) {
            for(let key in this.deviceListParams.checkInput){
                if(this.deviceListParams.checkInput[key] === row.id){
                    this.deviceListParams.checkInput.splice(key,1);
                }
            }
        },
        /**
         * 获取所有生产厂家
         */
        async onManufacturerAll() {
            try {
                const { code,data } = await getManufacturerService()
                if(code === 'SUCCESS'){
                    this.manufacturerArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 拉取分类列表
         */
        async onDeviceListAll() {
            try {
                this.deviceLoading = true
                this.deviceListArray = {}
                let params = {
                    deviceType:this.deviceListParams.deviceType.length === 0?'':this.deviceListParams.deviceType[2],
                    manufacturer:this.deviceListParams.manufacturer || '',
                    search:this.deviceListParams.search,
                    displayNumber:this.deviceListParams.displayNumber,
                    currentPage:this.deviceListParams.currentPage
                }
                const { code,data } = await getDeviceListService(params)
                if(code === 'SUCCESS'){
                    this.deviceListArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.deviceLoading = false
            }catch (error){
                this.deviceLoading = false
                console.log(error)
            }
        },
        /**
         * 删除设备信息
         */
        async onDeviceList(id) {
            try {
                let params = {
                    equipmentId:id
                }
                const { code } = await delDeviceInfoService(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功！')
                    if(this.deviceListArray.content.length === 1 && this.deviceListArray.currentPage > 1){
                        this.deviceListParams.currentPage=this.deviceListParams.currentPage-1
                    }
                    this.onDeviceListAll().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
    },
    destroyed () {
        $event.$off([EVENT_NAME.DEVICE_MENU_LIST,])
        window.removeEventListener('resize',this.onTableHeight)
    }
}
export default DeviceList
